import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import oneImage from '../assets/png/Freelance Dashboard.png'
import twoImage from '../assets/png/Hazy Network.png'
import threeImage from '../assets/png/Annas Desserts.png'
import fourImage from '../assets/png/Reliance Quartz.png'
import fiveImage from '../assets/png/Cupid Cloth Cutting.png'
import toarminas from '../assets/png/toarminas.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'Toarmina\'s Pizza - Canton',
        projectDesc: 'This fully dynamic website consist user experience as front end as well as admin portal as backend which gives admin control to handle all the data and images of this website of Pizza Store.',
        tags: ['Codeigniter', 'PHP' ,'MySQL', 'HTML5', 'CSS3', 'Bootstrap'],
        code: 'https://www.toarminascanton.com/',
        demo: 'https://www.toarminascanton.com/',
        image: toarminas
    },
    {
        id: 2,
        projectName: 'Freelance Dashboard',
        projectDesc: 'This project provides the project and client management features to all the freelancers who also want to manage their day to day activities.',
        tags: ['React', 'Node.js' ,'Express.js', 'MongoDB'],
        code: 'https://github.com/SanketShah34/Freelance_Dashboard',
        demo: 'https://csci5709-group5-s21.herokuapp.com/',
        image: oneImage
    },
    {
        id: 3,
        projectName: 'Hazy Network',
        projectDesc: 'This is a educational course providing an e-commerce website hazynetwork.com. I have developed this project from end to end for my client from requirements gathering to delivery.',
        tags: ['WordPress', 'MySQL', 'PHP','CSS'],
        code: 'https://hazynetwork.com',
        demo: 'https://hazynetwork.com',
        image: twoImage
    },
    {
        id: 4,
        projectName: 'Anna\'s Desserts',
        projectDesc: 'This project is for the client who provides delicious desserts to the customers. This website helps satisfy people\'s cravings of sweet tooth.',
        tags: ['PHP', 'HTML', 'Bootstrap', 'CSS'],
        code: 'https://github.com/SanketShah34/Annas-dessert',
        demo: 'annasdesserts.com',
        image: threeImage
    },
    {
        id: 5,
        projectName: 'Reliance Quartz',
        projectDesc: 'Reliance Quartz is the top manufacturer of The Wall Clock and Photo Frames in India. The client\'s business expansion and network is span among all Indian territories to proide Best Wall Clock.',
        tags: ['WordPress', 'MySQL', 'PHP', 'CSS'],
        code: 'https://reliancequartz.in',
        demo: 'https://reliancequartz.in',
        image: fourImage
    },
    {
        id: 6,
        projectName: 'Cupid Cloth Cutting',
        projectDesc: 'Cupid Cloth Cutting is the India\' one of the biggest Cloth Cutting Machine manufacturers in India. CUPID is a synonym of pinnacle pioneering in the field of round knife cloth cutting machines.',
        tags: ['PHP', 'HTML', 'CSS', 'Javascript'],
        code: 'https://www.cupidclothcutting.com/',
        demo: 'https://www.cupidclothcutting.com/',
        image: fiveImage
    },
    
]
