import resume from '../assets/pdf/Resume_Sanket.pdf'
import portfolioimg from '../assets/portfolio.jpg'

export const headerData = {
    name: 'Sanket Shah',
    title: "Full Stack Developer",
    desciption:"Go With The Flow!! Don't keep that much patience that your dreams just stay as your dreams.",
    image: portfolioimg,
    resumePdf: resume
}
