export const socialsData = {
    github: 'https://github.com/SanketShah34',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/sanket3/',
    instagram: '',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}