export const educationData = [
    {
        id: 1,
        institution: 'Dalhousie University',
        course: 'Master of Applied Computer Science',
        startYear: '2020',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'KSV University',
        course: 'B.E. in Computer Engineering',
        startYear: '2015',
        endYear: '2019'
    }
]