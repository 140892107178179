export const achievementData = {
    bio : "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
    achievements : [
        {
            id : 1,
            title : 'Java Certified Foundations Associate: Design Patterns',
            details : 'A java design patterns course from Skillsoft',
            date : 'May 5, 2022',
            field : 'Coding',
            image : 'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/50714148'
        },
        {
            id : 2,
            title : 'Angular 11 Bootcamp: Session 1',
            details : 'An Angular 11 bootcamp from Skillsoft',
            date : 'January 30, 2023',
            field : 'Angular',
            image : 'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/67160172'
        },
        {
            id : 3,
            title : 'Angular 11 Bootcamp: Session 2',
            details : 'An Angular 11 bootcamp from Skillsoft',
            date : 'January 31, 2023',
            field : 'Angular',
            image : 'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/67230190'
        },
        {
            id : 4,
            title : 'AWS Developer Associate 2022: CloudFormation',
            details : 'An AWS Developer CloudFormation course from Skillsoft',
            date : 'Feb 2, 2023',
            field : 'AWS Cloudformation',
            image : 'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/67318602'
        },
        {
            id : 5,
            title : 'Natural Language Processing: Getting Started with NLP',
            details : 'A Natural Language Processing course from Skillsoft',
            date : 'Feb 28, 2023',
            field : 'Machine Learning',
            image : 'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/68948734'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/